@import 'variables';
@import 'mixins';

.contact {
  background: $white;
  padding: 100px;

  @include mQ(768px){
    padding: 50px;
  }


  section {
    form {
      label{
      }
      label + input {
        padding-left: 1rem;
      }
      input,textarea{
        background-color: $white;
        border: 1px solid $gray-600;

        &:focus{
          background-color: $gray-300;
        }
      }
      button{
        background-color: $gori-green-1;
        color: $white;

        &:hover,&.active{
          background-color: $gori-green;
          color: $gori-orange-1;
        }
      }
    }

    h1 {
      span {
        color: $gori-orange;
      }
    }
  }

  .text-green {
    color: $gori-orange;
  }

  .found-us {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .mapSection {
    @media (max-width: 1400px) {
      display: flex;
      flex-direction: column;
    }

    .firstMap, .secondMap {
      width: 50%;
      @media(max-width: 1400px) {
        width: 100%;
        text-align: center;
        margin: 1rem 0;
      }

      p{
        font-size: $step-3;
        font-weight: 500;
        color: $gori-green-2;
      }
    }

    .secondMap {
      position: relative;
      @media (min-width: 1400px) {
        iframe {
          position: absolute;
          top: 200px;
        }
        p {
          position: absolute;
          top: 150px;
        }
      }
    }

    iframe {
      width: 100%;
      min-height: 440px;
      max-width: 340px;
      border-radius: 25px;
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
      @keyframes shine {
        to {
          background-position-x: -200%;
        }
      }
    }
  }

  .fax, .phone, .email {

    p {
      font-size: 1rem;
      margin: 0;
      cursor: default;
    }
  }
  .fax{
    svg{
      stroke: $gori-orange;
      fill: $gori-orange;
    }
  }
  .phone, .email{
    svg{
      fill: $gori-orange;
    }
  }


  .hover:hover {
    scale: 1.2;
  }
}
