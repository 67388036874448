@import "variables";

.navbar {
  position: sticky;
  *{

    font-family: "Montserrat", "Roboto", "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  @media (min-width: 992px) {
    padding: 2rem 6rem;
  }

  .navbar-brand {
    @media (max-width: 992px) {
      width: 100%;
      text-align: center;
    }
  }

  .navbar-toggler {
    border: none;
    background-color: transparent;
  }

  .navbar-nav {
    text-align: center;

  }
  .nav-item{
    .nav-link{
      color: $gori-green;

      &:hover{
        color: $gori-orange-3;
      }
      &.active{
        color: $gori-orange;
      }
    }
  }

  .contact-link {
    min-width: 8rem;
  }
}