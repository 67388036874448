@import '_variables.scss';
@import 'mixins';

footer {
  padding: 2rem calc( 4rem + clamp(17.5px, 17.5%, 70px)) 2rem 4rem;
  background: linear-gradient(45deg, rgba($gori-green, 1) 50%, rgba($gori-green, 0.7)), url("../assets/hand-picking-up-coffee-bean-banner.jpg") no-repeat right ;
  background-size: cover;

  .footer-logo {
    text-align: justify;

    img {
      width: clamp(100px,100%,400px);
      max-width: 400px;
    }
    p{
      font-family: "Marcellus";
      color: $gori-orange;
      margin-left: clamp(17.5px,17.5%,70px);

      @include mQ(768px){

      }
    }
  }

  .footer-content{
    *{

      font-family: "Montserrat", "Roboto", "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
  }

  .footer-navigation, .footer-more, .footer-contact {
    display: flex;
    flex-direction: column;
    padding-top: clamp(17.5px,17.5%,70px);
    margin-bottom: 25px;
    @include mQ(768px){
      padding: 0px;
      margin-left: clamp(17.5px,17.5%,70px);
    }
  }


  h4 {
    margin-bottom: 15px;
    color: $gori-orange-2;
  }
  a{
    color: $gray-200;
    &:hover,&:active{
      color: $gori-orange-1;
    }
  }
}