@import "variables";
@import "mixins";

.sustainability {
  overflow: hidden ;
  section:not(:first-child){
    margin: 150px auto;
  }
  .sustainability-banner {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url("../assets/sustainability-banner.webp") no-repeat center;
    background-size: cover;
    background-position: center;
    min-height: 700px;

    h1 {
      margin: auto;
    }
  }

  .sustainability-section {
    @media (min-width: 992px) {
      padding: 2rem 8rem;
    }

    .left-collapse, .right-collapse {
      @media (min-width: 992px) {
      }
      padding: 1rem 2rem;
      height: 100%;
    }

    .left-text, .right-text {
      padding: 1rem 2rem;
    }
  }
  .sustainability-mid-section{
    margin: auto;
    div{
      width: 30%;
      padding: 1rem 2rem;
      height: 700px;
      background-size: contain;
      background-position: center;

      &.side-1{
        background: url("../assets/sustailability-2.webp");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
      &.side-2{
        background: url("../assets/sustailability-3.webp") no-repeat center;
        background-size: cover;
      }
      @include mQ(992px){
        width: 100%;
      }
    }
    p{
      width: 25%;
      @include mQ(992px){
        width: 100%;
        margin: 25px auto;
      }
    }
  }
}