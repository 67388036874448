@font-face {
    font-family: "Marcellus";
    src: local("Marcellus"),url("./assets/fonts/Marcellus-Regular.ttf") format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: "MarcellusSC";
    src: local("MarcellusSC"),url("./assets/fonts/MarcellusSC-Regular.ttf") format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: "Adobe Garamond Pro";
    src: local("AGaramondPro"),url("./assets/fonts/AGaramondPro-Regular.otf") format('opentype');
    font-weight: normal;
}
@font-face {
    font-family: "Adobe Garamond Pro";
    src: local("AGaramondPro"),url("./assets/fonts/AGaramondPro-Bold.otf") format('opentype');
    font-weight: bold;
}
@font-face {
    font-family: "Adobe Garamond Pro Italic";
    src: local("AGaramondPro"),url("./assets/fonts/AGaramondPro-Italic.otf") format('opentype');
    font-weight: normal;
}
@font-face {
    font-family: "Adobe Garamond Pro Italic";
    src: local("AGaramondPro"),url("./assets/fonts/AGaramondPro-BoldItalic.otf") format('opentype');
    font-weight: bold;
}

body {
    box-sizing: border-box;
    margin: 0;
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #e9ecef;
}

a {
    text-decoration: none;
    color: black;
}

a:hover {
    scale: 1.03;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}