@import 'variables';
@import 'mixins';

.home {
  .home-intro {
    @media (min-width: 992px) {
      padding: 2rem 4rem;
    }
    div:has(.image-grid){
      width: 54%;
      @include mQ(992px){
        width: 100%;
      }
    }

    .image-grid {
      position: relative;
      width: 100%;
      min-height: 800px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;


      img {
        width: 50%;
        height: auto ;
        @include mQ(992px){
          width: 100%;
        }
      }


      .top-image, .bottom-image {

      }

      .top-image {
        margin-left: 45%;
        margin-bottom: -40%;
        @include mQ(992px){
          margin: 40px 0px 40px;
        }
      }

      .bottom-image {
        margin-right: 45%;
        margin-top: -40%;
        @include mQ(992px){
          margin: 0px 0px 40px;
        }
      }
    }
  }

  .intro-text {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 45%;
      *{
        color: $black;
      }
      h2{
        margin: 45px auto;
      }

    @include mQ(992px){
      width: 100%;
    }

    .home-intro-welcome-text {
      text-align: center;
    }
  }

  .home-info {
    //background: linear-gradient(225deg, rgba($gori-green, 1) 0%, rgba(255,255,255, 0.1) 60%);
    overflow: hidden;
    min-height: 100vh;
    @media (min-width: 992px) {
      padding: 2rem 4rem;
    }

    div:has(.image-grid){
      width: 54%;
      @include mQ(992px){
        width: 100%;
      }
    }

    .image-grid {
      position: relative;
      width: 100%;
      min-height: 800px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;




      .top-image {
        margin-left: 45%;
        margin-bottom: 17%;
        width: 50%;
        z-index: 100;
        @include mQ(992px){
          width: 100%;
          margin: 40px 0px 40px;
        }
      }

      .bottom-image {
        margin-right: 45%;
        margin-top: -60%;
        width: 50%;
        z-index: 101;
        @include mQ(992px){

          width: 100%;
          margin: 0px 0px 40px;
        }
      }
      .third-image{
        margin-left: 46%;
        margin-top: -40%;
        width: 40%;
        z-index: 99;
        @include mQ(992px){
          width: 100%;
          margin: 0px 0px 40px;
        }

      }
      img {
        height: auto;
        @include mQ(992px){
          width: 100%;
        }
      }
    }

    .text-content{
      width: 45%;
      @include mQ(992px){
        width: 100%;
      }
    }
  }

  .down-arrow {
    display: flex;
    margin: 2rem auto 2rem auto;
    cursor: pointer;
  }

  .home-quote {
    position: relative;
    background: linear-gradient(rgba($gori-green-3,0.3), rgba($gori-green-3,0.3)), url("../assets/quote-bg.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    min-height: 500px;

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      z-index: -1;
      background-color: black;
      opacity: 0.2;
    }

    h2 {
      margin: 0 4rem;
      font-size: $step-3;
      font-weight: 200;
    }

    #quote {
      font-size: 2.5rem;

      span {
        font-weight: 700;
        font-size: $step-4;
      }
    }
  }

  .card-container {
    display: flex;
    justify-content: center;
    background-color: $white;
    padding: 10rem;

    .card {
      margin: 0 1rem;
      box-shadow: 0 9px 31px -3px rgba(0, 0, 0, 0.25);
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;

      .card-image {
        width: 415px;
        height: 450px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }

      .card-text {
        padding: 1rem;
      }
    }
  }
}
