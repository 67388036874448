// Mixins
// Background Cover Mixin
@import "variables";

@mixin background-cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

// Font Mixins
@mixin body-font {
  font-family: "Montserrat", "Roboto", "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@mixin mQ($arg) {
  @media screen and (max-width: $arg) {
    @content;
  }
}

@mixin mQ-min($arg) {
  @media screen and (min-width: $arg) {
    @content;
  }
}

@mixin clearFix {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin buttonMain {
  font-weight: 600;
  font-size: 16px;

  @include mQ(992px) {
    font-size: 14px;
  }

  background-color: $gesha-green;
  padding: 5px;
  width: 100%;
  height: 45px;
  border-radius: 35px;
  border: 0;
  color: $white;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: $gesha-dark-green;
  }

  &:active {
    background-color: $gesha-dark-green;
  }

  &:disabled,
  &[disabled] {
    background-color: $gray-500  !important;
  }
}

@mixin buttonBorder {
  padding: 5px;
  width: 100%;
  height: 50px;
  border-radius: 35px;
  border: 3px solid;
  color: $gesha-green;
  transition: 0.3s ease-in-out;

  &:hover {
    border-color: $gesha-dark-green;
  }

  &:active {
    background-color: $gesha-dark-green;
  }

  &:disabled,
  &[disabled] {
    background-color: $gray-500  !important;
  }
}

@mixin textBox {
  border-radius: 35px;
  color: $black;
  border: 1px solid $gray-500;
  width: 100%;
  height: 50px;
  padding: 8px;
  transition: 0.3s ease-in-out;

  &:hover {
    border: 2px solid $black;
  }

  &:focus {
    border: 2px solid $black;
    outline: none;
  }
}

@mixin link {
  font-weight: 600;
  color: $gesha-dark-green;
  text-decoration: none;
  font-size: 16px;

  @include mQ(992px) {
    font-size: 14px;
  }

  &:hover,
  &:active {
    color: $gesha-dark-green;
  }
}