@import "variables";
@import "mixins";

* {
  scroll-behavior: smooth !important;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Adobe Garamond Pro","Montserrat", "Roboto", "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 100%;

  ::-webkit-scrollbar {
    width: 8px;
    height: 100%;
  }

  ::-webkit-scrollbar-track {
    background-color: $gray-300;
    border-radius: 5px;
    transition: all 0.5s;
    &:hover {
      background-color: $dark;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: $gori-green-1;
    transition: all 0.5s;
    border-radius: 5px;
    &:hover {
      background-color: $gori-orange;
    }
  }
}

body {
  min-width: 320px;
  margin: auto;
  overflow-x: hidden;
  position: relative;
}

h1 {
  font-family: MarcellusSC;
  font-size: $step-5;
}

h2 {
  font-family: Marcellus;
  font-size: $step-4;
}

h1, h2 {
  @include mQ(992px) {
    font-weight: 800;
  }
}

h3 {
  font-weight: bold;
  font-size: $step-3;
}

h4 {
  font-weight: 500;
  font-size: $step-2;
}

h5 {
  font-weight: 500;
  font-size: $step-1;
}

p {
  font-size: $step-1;
}

a {
  @include link;
  cursor: pointer;
}

button {
  @include buttonMain;

  &:hover, &:focus {
    outline: none;
  }

  &:disabled,
  &[disabled] {
    background-color: $gray-500 !important;
  }
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="combobox"],
input[type="date"],
textarea {
  @include textBox;
}

ul {
  list-style: none;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;

  @include mQ(992px) {
    font-size: 14px;
  }
}
