@import 'variables';
@import 'mixins';

.timeline {
  //overflow: hidden;
  .banner-1, .banner-2, .banner-3 {
    padding: 4rem 8rem;
    min-height: 640px;
    text-align: justify;

    &:hover {
      background-size: cover;
    }
  }


  .banner-1 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets/hand-picking-up-coffee-bean-banner.jpg") no-repeat;
  }

  .banner-2 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets/history-of-ethiopia-banner.jpg") no-repeat;
  }

  .banner-3 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets/minit-tribe-banner.webp") no-repeat;
  }

  .large-1, .large-2, .large-3 {
    background: $white;
  }

  .banner {
    position: relative;
    background-color: $gori-green-2;

    .arrow {
      transition: 500ms;  
    }
  }

  .banner-1, .banner-2, .banner-3 {
    background-size: 100% auto;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 1s all;
    cursor: pointer;
    position: relative;
    z-index: 1000;

    button {
      margin: 1rem 0;
      background-color: transparent;
      border: none;
    }

    p {
      font-family: "MarcellusSC", "Montserrat", "Roboto", "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: $step-7;
      font-weight: 300;
      color: $white;
      transition: 1s all;
      text-align: center;
    }

    &.active {
      min-height: 240px;
      position: sticky;
      top: 0;
      p {
        font-size: $step-5;
      }
    }

    &:hover {
      background-size: 110% auto;
    }

    svg{
      width: 50px;
      height: auto;
      stroke: $white;
      transition: 1s all;
    }

    &:hover svg {
      scale: 1.3;
    }

    @include mQ(768px) {
      background-size: auto 100%;
      &:hover {
        background-size: auto 110%;
      }
    }
  }

  .content {
    position: relative;
    max-height: 0px;
    overflow: hidden;
    width: 100%;
    opacity: 0;

    * {
      text-align: justify;
      text-justify: inter-word;
      color: $black;
    }

    img {
      width: auto;
      height: 100%;
    }

    &.active {
      max-height: 5000px;
      //transition: 0.1s all;
      opacity: 1;
      //position: relative;
    }
    &>div{
      .paragraph{

        p{
          padding: 100px;
          width: 45%;
          text-align: center;
          color: $gray-300;
          font-size: $step-1;
          @include mQ(992px){
            width: 100%;
          }
        }
        img{
          width: 53%;
          height: auto;

          &.left{
            -webkit-mask-image:-webkit-gradient(linear, right top, left top , from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
            mask-image: linear-gradient(to left, rgba(0,0,0,1) 50%, rgba(0,0,0,0));

          }
          &.right{
            -webkit-mask-image:-webkit-gradient(linear, left top, right top , from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
            mask-image: linear-gradient(to right, rgba(0,0,0,1) 50%, rgba(0,0,0,0));

          }
          @include mQ(992px){
            width: 100%;
            &.left,&.right{
              -webkit-mask-image:-webkit-gradient(linear, left bottom, left top , from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
              mask-image: linear-gradient(to top, rgba(0,0,0,1) 50%, rgba(0,0,0,0));

            }
          }


        }
      }
    }
  }
}