// Restated Bootstrap Variables
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$primary: $blue  !default;
$secondary: $gray-600  !default;
$success: $green  !default;
$info: $cyan  !default;
$warning: $yellow  !default;
$danger: $red  !default;
$light: $gray-100  !default;
$dark: $gray-800  !default;

//Gori Gesha Colors
$white: $gray-200;
$gesha-background-white: $gray-200;
$gesha-green: #5F8825;
$gesha-brown: #734636;
$gesha-dark-green: #2E5903;
$gori-orange: #be6e43;
$gori-orange-1: #d57f52;
$gori-orange-2: #ab5e31;
$gori-orange-3: #974b22;
$gori-green: #002e36;
$gori-green-1: #0e4751;
$gori-green-2: #0a323d;
$gori-green-3: #001F27;

//Fluid type scale

$step--2: clamp(0.79rem, calc(0.79rem + 0.01vw), 0.80rem);
$step--1: clamp(0.89rem, calc(0.87rem + 0.10vw), 1.00rem);
$step-0: clamp(1.00rem, calc(0.96rem + 0.22vw), 1.25rem);
$step-1: clamp(1.13rem, calc(1.05rem + 0.38vw), 1.56rem);
$step-2: clamp(1.27rem, calc(1.15rem + 0.60vw), 1.95rem);
$step-3: clamp(1.42rem, calc(1.25rem + 0.88vw), 2.44rem);
$step-4: clamp(1.60rem, calc(1.35rem + 1.26vw), 3.05rem);
$step-5: clamp(1.80rem, calc(1.45rem + 1.75vw), 3.82rem);
$step-6: clamp(2.03rem, calc(1.55rem + 2.38vw), 4.77rem);
$step-7: clamp(2.28rem, calc(1.64rem + 3.20vw), 5.96rem);
$step-8: clamp(2.57rem, calc(1.72rem + 4.25vw), 7.45rem);
$step-9: clamp(2.89rem, calc(1.77rem + 5.59vw), 9.31rem);
$step-10: clamp(3.25rem, calc(1.79rem + 7.30vw), 11.64rem);