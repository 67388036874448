.hero {
  background-image: url("../assets/home-background.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-attachment: fixed;
  min-height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  div {
    width: fit-content;
    height: fit-content;
  }

  .logo {
    display: inline-flex;
    position: sticky;
    height: fit-content;
    width: fit-content;
    top: 1%;
    justify-content: center;
    align-items: center;
  }

  .hero-image, svg {
    width: 100%;
    height: auto;
    top: 1%;

    @media (max-width: 996px) {
      min-width: 300px;
    }

  }

  .navbar {
    position: sticky;
    top: 1%;
    margin-left: auto;
  }

  @media (max-width: 996px) {
    max-height: 40vh;
    padding-bottom: 3rem;
  }
}
